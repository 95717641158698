import React from 'react';

const Footer = () => (
  <footer className="py-8 bg-gray-800 text-white text-center">
    <p>&copy; 2024 CricSphere. All Rights Reserved.</p>
    <p className="mt-4">
      Follow us on 
      <a href="https://wa.link/interaktadsgoogle" className="text-yellow-500 mx-2">Facebook</a>, 
      <a href="https://wa.link/interaktadsgoogle" className="text-yellow-500 mx-2">Twitter</a>, 
      and 
      <a href="https://wa.link/interaktadsgoogle" className="text-yellow-500 mx-2">Instagram</a>.
    </p>
  </footer>
);

export default Footer;
