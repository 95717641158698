import React from 'react';

const PlayerStatsSection = () => (
  <section className="py-16 bg-gray-800 text-white">
    <div className="container mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12">Player Highlights</h2>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-12">
        <div
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-gray-700 p-6 rounded-lg text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img
            src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQAZshN_vYDKdplQwPMQ2L7SOnS7owolPUbe5js7ZI9xxn5mluE"
            alt="Ahmed Raza"
            className="w-32 h-32 rounded-full mx-auto mb-4"
          />
          <h3 className="text-2xl font-semibold mb-4">Ahmed Raza</h3>
          <p>Matches: 50</p>
          <p>Runs: 1200</p>
          <p>Wickets: 100</p>
          <p>Batting Average: 40.00</p>
          <p>Bowling Economy: 7.5</p>
        </div>
        <div
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-gray-700 p-6 rounded-lg text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbmYO_HalbuotHzaSU4g8RB4krltmCevcoye8ZfKPxIO3xIqVE"
            alt="Rohan Mustafa"
            className="w-32 h-32 rounded-full mx-auto mb-4"
          />
          <h3 className="text-2xl font-semibold mb-4">Rohan Mustafa</h3>
          <p>Matches: 40</p>
          <p>Runs: 900</p>
          <p>Wickets: 80</p>
          <p>Batting Average: 35.00</p>
          <p>Bowling Economy: 6.9</p>
        </div>
        <div
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-gray-700 p-6 rounded-lg text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img
            src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQkRCffwVGwrWJLxMJAXI7ZERAFLnhfvlM5i0cMXNsp7G5-en5b"
            alt="Ali Khan"
            className="w-32 h-32 rounded-full mx-auto mb-4"
          />
          <h3 className="text-2xl font-semibold mb-4">Ali Khan</h3>
          <p>Matches: 30</p>
          <p>Runs: 800</p>
          <p>Wickets: 60</p>
          <p>Batting Average: 38.00</p>
          <p>Bowling Economy: 7.0</p>
        </div>
        <div 
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-gray-700 p-6 rounded-lg text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQufMzfnUaF3PAwT1OQtGb_Io5zF4Xu9uhm3PDqrXYAO_rSSnHp"
            alt="Fahad Nawaz"
            className="w-32 h-32 rounded-full mx-auto mb-4"
          />
          <h3 className="text-2xl font-semibold mb-4">Fahad Nawaz</h3>
          <p>Matches: 25</p>
          <p>Runs: 650</p>
          <p>Wickets: 45</p>
          <p>Batting Average: 42.00</p>
          <p>Bowling Economy: 6.5</p>
        </div>
        <div
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-gray-700 p-6 rounded-lg text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img
            src="https://cricclubs.com/documentsRep/profilePics/1cfbb29e-19cb-49ed-b150-8a7640d8d492.jpeg"
            alt="Imran Asif"
            className="w-32 h-32 rounded-full mx-auto mb-4"
          />
          <h3 className="text-2xl font-semibold mb-4">Imran Asif</h3>
          <p>Matches: 38</p>
          <p>Runs: 950</p>
          <p>Wickets: 85</p>
          <p>Batting Average: 37.00</p>
          <p>Bowling Economy: 7.1</p>
        </div>
        <div
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-gray-700 p-6 rounded-lg text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img
            src="https://img1.hscicdn.com/image/upload/f_auto,t_ds_square_w_800,q_50/lsci/db/PICTURES/CMS/101600/101673.jpg"
            alt="Zaid Shah"
            className="w-32 h-32 rounded-full mx-auto mb-4"
          />
          <h3 className="text-2xl font-semibold mb-4">Zaid Shah</h3>
          <p>Matches: 28</p>
          <p>Runs: 700</p>
          <p>Wickets: 50</p>
          <p>Batting Average: 32.00</p>
          <p>Bowling Economy: 6.8</p>
        </div>
        <div 
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-gray-700 p-6 rounded-lg text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img
            src="https://usmasters.ttensports.com/wp-content/uploads/2023/06/Sultan-Ahmad.jpg"
            alt="Sultan Ahmed"
            className="w-32 h-32 rounded-full mx-auto mb-4"
          />
          <h3 className="text-2xl font-semibold mb-4">Sultan Ahmed</h3>
          <p>Matches: 22</p>
          <p>Runs: 600</p>
          <p>Wickets: 55</p>
          <p>Batting Average: 28.00</p>
          <p>Bowling Economy: 6.2</p>
        </div>
        <div
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-gray-700 p-6 rounded-lg text-center shadow-lg hover:shadow-2xl transition-shadow">
          <img
            src="https://media.gettyimages.com/id/1499292544/photo/bulawayo-zimbabwe-junaid-siddique-of-united-arab-emirates-poses-for-a-photograph-prior-to-the.jpg?s=612x612&w=gi&k=20&c=DllEi-fHSAroxp5ORigjfSMzzqVBkvFeENja3gzkb-4="
            alt="Junaid Siddique"
            className="w-32 h-32 rounded-full mx-auto mb-4"
          />
          <h3 className="text-2xl font-semibold mb-4">Junaid Siddique</h3>
          <p>Matches: 35</p>
          <p>Runs: 820</p>
          <p>Wickets: 75</p>
          <p>Batting Average: 33.00</p>
          <p>Bowling Economy: 7.2</p>
        </div>
      </div>
    </div>
  </section>
);

export default PlayerStatsSection;
