// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
// import Homepage from './Components/khelocricket/homepage';
// import PrivacyPolicy from './Components/khelocricket/Privacypolicy';
// import TermsConditions from './Components/khelocricket/Termscondition';
// import Cookiespolicy from './Components/khelocricket/cookies';
// import Navbar from './Components/khelocricket/navbar';
// import Footer from './Components/khelocricket/Footer';
// import ScrollToTop from './Components/khelocricket/Scrollontop';
import LandingPage from "../../phantom/src/Components/ajmnabolts/Landingpage"
function App() {
  return (
    // <Router>
    //   <div className="App">
    //     <ScrollToTop/>
    //     <Navbar/>
    //     <Routes>
    //       <Route path="/" element={<Homepage />} />
    //       <Route path="/privacy" element={<PrivacyPolicy />} />
    //       <Route path="/terms" element={<TermsConditions />} />
    //       <Route path="/cookies" element={<Cookiespolicy />} />
    //     </Routes>
    //     <Footer/>
    //   </div>
    // </Router>

    <div>

      <LandingPage />

    </div>
  );
}

export default App;
