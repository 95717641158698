import React from 'react';

const MatchHighlightsSection = ({ highlights }) => (
  <section className="py-16 bg-gray-100">
    <div className="container mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12">Match Highlights</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        {highlights.map((highlight, index) => (
          <div
          onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
          key={index} className="bg-white p-6 rounded-lg shadow-lg">
            <img
              src={highlight.imageUrl}
              alt={highlight.title}
              className="w-full h-40 object-cover rounded-md mb-4"
            />
            <h3 className="text-2xl font-bold">{highlight.title}</h3>
            <p className="text-gray-600">Date: {highlight.date}</p>
            <a
              href="https://wa.link/interaktadsgoogle"
              className="text-yellow-500 font-semibold hover:underline mt-4 block"
            >
              Watch Now
            </a>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default MatchHighlightsSection;
