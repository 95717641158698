import React from 'react';
import HeroSection from './hero';
import TeamOverviewSection from './teamoverview';
import UpcomingMatchesSection from './upcommingmatches';
import PlayerStatsSection from './playerstats';
import NewsSection from './newssect';
import FanZoneSection from './fanzone';
import MatchHighlightsSection from './matchhighlights';
import MerchandiseSection from './mechandisesec';
import Footer from './footer';

const LandingPage = () => {
  const upcomingMatches = [
        {
          opponent: 'Dubai Chargers',
          date: 'November 20, 2024',
          time: '6:00 PM',
          venue: 'Ajman Cricket Ground',
          link: '#',
          image: 'https://img1.hscicdn.com/image/upload/f_auto,t_ds_square_w_160,q_50/lsci/db/PICTURES/CMS/349600/349619.jpg',
          keyPlayer: 'Ahmed Raza',
          preview: 'Ajman Bolts will face off against Dubai Chargers in an exciting match. Expect fireworks from both teams!'
        },
        {
          opponent: 'Sharjah Strikers',
          date: 'November 25, 2024',
          time: '7:00 PM',
          venue: 'Sharjah Stadium',
          link: '#',
          image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgbpP4RJ50UK5Hq9nSKkztrynuEKxnGu_AyQ&s',
          keyPlayer: 'Khalid Awan',
          preview: 'Sharjah Strikers are ready to challenge the Ajman Bolts in what promises to be a thrilling contest!'
        },
        {
          opponent: 'Abu Dhabi Tigers',
          date: 'December 1, 2024',
          time: '5:00 PM',
          venue: 'Abu Dhabi Cricket Ground',
          link: '#',
          image: 'https://img1.hscicdn.com/image/upload/f_auto,t_ds_w_960,q_50/lsci/db/PICTURES/CMS/372100/372184.jpg',
          keyPlayer: 'Mohammad Irfan',
          preview: 'Abu Dhabi Tigers are looking to take on Ajman Bolts in a battle of the titans in the upcoming match!'
        },
        {
          opponent: 'Fujairah Falcons',
          date: 'December 5, 2024',
          time: '8:00 PM',
          venue: 'Fujairah Cricket Stadium',
          link: '#',
          image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8HwGzQCZa9mZlDOfYrjEv3EadyTtI_VW3pA&s',
          keyPlayer: 'Usman Khan',
          preview: 'Ajman Bolts will look to defend their title as they face off against Fujairah Falcons in this much-anticipated match.'
        },
        {
          opponent: 'Al Ain Lions',
          date: 'December 10, 2024',
          time: '7:30 PM',
          venue: 'Al Ain Sports Complex',
          link: '#',
          image: 'https://i.pinimg.com/736x/9b/f8/2b/9bf82b6d447a0007a95f9a14a3bdef62.jpg',
          keyPlayer: 'Ali Khan',
          preview: 'The Al Ain Lions are ready to challenge Ajman Bolts in this action-packed evening match.'
        }
      
  ];

  const teamStats = {
    matchesPlayed: 50,
    wins: 35,
    losses: 15,
    bestPlayer: 'Ahmed Raza',
  };

  const news = [
    {
      title: "Northern Warriors Begin Abu Dhabi T10 Campaign",
      description: "The Northern Warriors are set to kick off their campaign in the Abu Dhabi T10 League with high hopes, led by experienced players and a determined coaching team.",
      link: "#",
    },
    {
      title: "Northern Warriors Announce Power-Packed Squad",
      description: "With a blend of power hitters and skilled bowlers, the Northern Warriors aim to bring their A-game to the fast-paced Abu Dhabi T10 League.",
      link: "#",
    },
    {
      title: "Jacob Oram Highlights the Importance of Top-Order Batting",
      description: "Assistant coach Jacob Oram emphasizes that strong performances from the top order will be pivotal to the team's success this season.",
      link: "#",
    },
    {
      title: "Northern Warriors Fans Rally Behind Their Team",
      description: "Fans are excited for the Warriors' matches, as the team gears up to face tough competition in the T10 League.",
      link: "#",
    },
    {
      title: "Abu Dhabi T10 League Kicks Off",
      description: "The highly anticipated Abu Dhabi T10 League has started, with the Northern Warriors looking to claim their third championship title.",
      link: "#",
    },
  ];
  
  

  const highlights = [
    {
      title: 'Northern Warriors vs Delhi Bulls',
      date: 'Sun, 22 Nov 2024',
      imageUrl: 'https://cdn.dnaindia.com/sites/default/files/styles/full/public/2021/01/30/953986-northern-warriors-vs-delhi-bulls.jpg',
    },
    {
        title: 'Ajman Bolts vs Sharjah Falcons',
        date: 'Mon, 29 Nov 2024',
        imageUrl: 'https://i.ytimg.com/vi/Rif2sRfEiVY/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLC3sGg8zu9s3GCiIFXGfLXDuNTdSA',
      },
  ];


  return (
    <div>

        <HeroSection/>
        <MatchHighlightsSection highlights={highlights}/>
        <NewsSection news={news}/>
        <TeamOverviewSection stats={teamStats}/>
        <UpcomingMatchesSection matches={upcomingMatches}/>
        <PlayerStatsSection/>
        <FanZoneSection/>
        <Footer/>

        {/* <MatchHighlightsSection/> */}
        {/* <MerchandiseSection items={merchandise}/> */}
        {/* <Footer/> */}
      {/* <HeroSection />
      <TeamOverviewSection stats={teamStats} />
      <UpcomingMatchesSection matches={upcomingMatches} />
      <PlayerStatsSection />
      <NewsSection news={news} />
      <FanZoneSection />
      <MatchHighlightsSection highlights={highlights} />
      <MerchandiseSection items={merchandise} />
      <Footer /> */}
    </div>
  );
};

export default LandingPage;
