import React from "react";

import { GrTwitter } from "react-icons/gr";
import { IoMdTrophy } from "react-icons/io";
import { LiaUserFriendsSolid } from "react-icons/lia";

const FanZoneSection = () => (
  <section className="py-16 bg-yellow-500 text-gray-800">
    <div className="container mx-auto">
      <h2 className="text-5xl font-bold text-center mb-12 text-white">
        Join the Fan Zone
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        <div
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")} 
        className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:scale-105">
          <div className="text-4xl text-yellow-500 mb-6">
            <IoMdTrophy />
          </div>
          <h3 className="text-2xl font-semibold mb-4">Fan Contests</h3>
          <p className="text-lg text-gray-700 mb-4">
            Participate in exciting contests and win awesome prizes. Show your
            team spirit and win exclusive rewards!
          </p>
          <a href="#" className="text-yellow-600 font-semibold hover:underline">
            Join Now
          </a>
        </div>
        <div
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:scale-105">
          <div className="text-4xl text-yellow-500 mb-6">
            <GrTwitter />
          </div>
          <h3 className="text-2xl font-semibold mb-4">
            Social Media Shoutouts
          </h3>
          <p className="text-lg text-gray-700 mb-4">
            Tag us in your posts for a chance to get featured on our official
            channels. Show off your fan pride!
          </p>
          <a href="#" className="text-yellow-600 font-semibold hover:underline">
            Tag Us Now
          </a>
        </div>
        <div
         onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:scale-105">
          <div className="text-4xl text-yellow-500 mb-6">
            <LiaUserFriendsSolid />
          </div>
          <h3 className="text-2xl font-semibold mb-4">
            Exclusive Meet & Greet
          </h3>
          <p className="text-lg text-gray-700 mb-4">
            Join special events and meet your favorite players in person! Get an
            exclusive experience and make memories.
          </p>
          <a href="#" className="text-yellow-600 font-semibold hover:underline">
            Register Now
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default FanZoneSection;
