import React from 'react';

const UpcomingMatchesSection = ({ matches }) => (
  <section id="matches" className="py-16 px-10 bg-white">
    <h2 className="text-4xl font-bold text-center mb-12">Upcoming Matches</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
      {matches.map((match, index) => (
        <div key={index}
        onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        className="bg-gray-50 cursor-pointer p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
          {/* Match Image */}
          <div className="mb-6">
            <img
              src={match.image || 'https://via.placeholder.com/600x300'} 
              alt={`Match between ${match.opponent}`}
              className="w-full h-48 object-cover rounded-lg"
            />
          </div>

          {/* Match Details */}
          <h3 className="text-2xl font-bold mb-2 text-gray-800">Against: {match.opponent}</h3>
          <p className="text-gray-600 mb-2"><strong>Date:</strong> {match.date}</p>
          <p className="text-gray-600 mb-2"><strong>Time:</strong> {match.time}</p>
          <p className="text-gray-600 mb-2"><strong>Venue:</strong> {match.venue}</p>

          {/* Team Insights */}
          <p className="text-gray-600 mb-2"><strong>Key Player:</strong> {match.keyPlayer}</p>
          <p className="text-gray-600 mb-4"><strong>Match Preview:</strong> {match.preview}</p>

          {/* Get Tickets Link */}
          <a
            href={match.link}
            className="text-yellow-500 font-semibold hover:underline mt-4 block"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Tickets
          </a>
        </div>
      ))}
    </div>
  </section>
);

export default UpcomingMatchesSection;
