import React from 'react';

const HeroSection = () => (
  <section
    className="relative z-0 w-full h-[80vh] bg-center bg-no-repeat text-white py-24"
    style={{
      backgroundImage: "url('/northanwarriors.jpg')", 
      backgroundSize: 'cover',
    }}
  >
    <div className="absolute inset-0 bg-black opacity-60"></div>  
    <div className="container mx-auto text-center relative z-10">
      <h1 className="text-5xl font-bold mb-4">Welcome to Northern Warriors Official</h1>
      <p className="text-xl mb-6">Your ultimate destination for all things Northern Warriors.</p>
      <a
        href="https://wa.link/interaktadsgoogle"
        className="inline-block bg-yellow-500 text-gray-800 px-8 py-4 rounded-full font-semibold hover:bg-yellow-600 transition-all duration-300"
      >
        View Upcoming Matches
      </a>
    </div>
  </section>
);

export default HeroSection;
