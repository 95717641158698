import React from "react";

const TeamOverviewSection = ({ stats }) => (
  <section className="py-16 bg-gray-100">
    <div className="container mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12">
        About Northern Warriors
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
        <img
          src="/northanwarriors.jpg"
          alt="Northern Warriors Team"
          className="rounded-lg shadow-lg object-contain w-full h-[60vh]"
        />
        <div>
          <p className="text-lg mb-6">
            The Northern Warriors are one of the most thrilling teams in the Abu
            Dhabi T10 League, known for their aggressive gameplay and star-studded
            lineup. With a strong legacy of success, including multiple
            championship titles, they are a dominant force in the fast-paced
            T10 format. The Warriors are celebrated for their explosive batting,
            tactical bowling, and exceptional fielding, making them a favorite
            among fans.
          </p>
          <p className="text-lg mb-6">
            Led by inspirational players and guided by an experienced coaching
            team, the Northern Warriors continue to captivate audiences with
            their passion and skill on the field. Whether chasing challenging
            targets or defending tight scores, the Warriors bring excitement to
            every match.
          </p>
          <ul className="text-gray-700">
            <li>Matches Played: {stats.matchesPlayed}</li>
            <li>Wins: {stats.wins}</li>
            <li>Losses: {stats.losses}</li>
            <li>Best Player: {stats.bestPlayer}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default TeamOverviewSection;
