import React from 'react';

const NewsSection = ({ news }) => (
  <section className="py-16 px-10 bg-gray-100">
    <h2 className="text-4xl font-bold text-center mb-12">Latest News</h2>
    <div className="grid grid-cols-1 gap-8">
      {news.map((item, index) => (
        <div
        onClick={() => window.open("https://wa.link/interaktadsgoogle", "_blank")}
        key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow">
          <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
          <p className="text-gray-700 text-sm mb-4">{item.description}</p>
          <a
            href={item.link}
            className="text-yellow-500 font-semibold hover:underline mt-2 block"
          >
            Read More
          </a>
        </div>
      ))}
    </div>
  </section>
);

export default NewsSection;
